<template>
  <vx-card title="On Route">
    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Collection Type</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-input class="w-full" type="text" value="On Route" disabled />
      </div>
    </div>
    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Operating Unit</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <multiselect
          v-model="selectedOperatingUnit"
          :options="optionOperatingUnit"
          placeholder="Type to search"
          track-by="name"
          label="name"
          :max-height="125"
          :allow-empty="false"
          @select="onSelectOperatingUnit"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} - {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} - {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <multiselect
          v-model="selectedTerritory"
          :options="optionTerritory"
          placeholder="Type to search"
          track-by="name"
          label="name"
          :max-height="125"
          :allow-empty="false"
          @select="onSelectTerritory"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} - {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} - {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Date</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-input class="w-full" type="date" v-model="date" />
      </div>
    </div>
    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Sales</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <multiselect
          v-model="selectedSales"
          :options="optionSales"
          placeholder="Type to search"
          track-by="personal_id"
          :custom-label="customLabelSales"
        >
          <span slot="noResult">Oops! No data found</span>
        </multiselect>
      </div>
    </div>
    <!--  -->
    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Search by Keyword</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-input class="w-full" type="text" v-model="searching_bar" placeholder="search by customer code or name or invoice number" />
      </div>
    </div>
    <!--  -->
    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Total Value</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-input class="w-full" readonly :value="formatNumber(total_value)" />
      </div>
    </div>

    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col">
        <vs-button color="primary" @click="onSubmitFilter()">
          Submit
        </vs-button>
      </div>
    </div>

    <!-- table -->
    <div class="vx-row">
      <div class="core vx-col w-full mb-base">
        <!-- search bar -->
        <!-- <div>
          <vs-input
            class="w-1/2 my-2"
            placeholder="search by customer code or name or invoice number"
            v-model="searchBar"
          />
        </div> -->
        <div>
          <vs-table
            stripe
            border
            description
            search
            :data="tablesData"
            :max-items="table.data.length"
            :sst="true"
            multiple
            v-model="selecteds"
            @search="onSearchTable"
          >
            <template slot="header">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div
                  class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                  <span class="mr-2"
                    >{{ this.table.start }} - {{ this.table.length }} of
                    {{ this.table.total }}</span
                  >
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                  <vs-dropdown-item
                    v-for="item in table.limits"
                    :key="item"
                    @click="handleChangelength(item)"
                  >
                    <span>{{ item }}</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </template>
            <template slot="thead">
              <vs-th>No</vs-th>
              <vs-th>Customer</vs-th>
              <vs-th>Ship To</vs-th>
              <vs-th>Invoice No</vs-th>
              <vs-th>Invoice Date</vs-th>
              <vs-th>Due Date</vs-th>
              <vs-th>Invoice Value</vs-th>
              <vs-th>Remaining Value</vs-th>
              <!-- <vs-th><vs-checkbox /> Invoice</vs-th> -->
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>{{ table.row_number_start + indextr }}</vs-td>
                <vs-td>{{ tr.customer }}</vs-td>
                <vs-td>{{ tr.ship_to }}</vs-td>
                <vs-td>{{ tr.invoice_no }}</vs-td>
                <vs-td>{{ tr.invoice_date | formatDate }}</vs-td>
                <vs-td>{{ tr.due_date | formatDate }}</vs-td>
                <vs-td>{{ tr.invoice_value | formatNumber }}</vs-td>
                <vs-td>{{ tr.remaining_value | formatNumber }}</vs-td>
                <!-- <vs-td
                  ><vs-checkbox
                    v-model="tr.use_invoice"
                    :val="tr.id"
                    :key="indextr"
                    :name="indextr"
                  ></vs-checkbox
                ></vs-td> -->
              </vs-tr>
            </template>
          </vs-table>

          <vs-pagination
            style="padding-top: 5px"
            :total="table.data.length > 0 ? table.totalPage : 0"
            v-model="setPage"
          />
        </div>
      </div>
    </div>

    <div class="vx-row mb-6 w-1/2">
      <div class="vx-col sm:w-1/4 flex items-center">
        <span>Notes</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-textarea v-model="notes" />
      </div>
    </div>

    <div class="vx-row mt-4">
      <div class="vs-col">
        <vs-button
          @click="onSaveForm"
          size="medium"
          color="success"
          icon-pack="feather"
          icon="icon-check"
          style="margin-right: 5px"
          >Save</vs-button
        >
      </div>
      <div class="vs-col">
        <vs-button
          @click="() => $router.push('/collection-plan-route')"
          size="medium"
          color="danger"
          icon-pack="feather"
          icon="icon-x"
          style="margin-right: 5px"
          >Close</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
import { formatNumber, uniqueArray } from "../../../../utils/common";

export default {
  data() {
    return {
      baseUrl: "/api/sfa/v1/collection-plan/list-route",
      selectedOperatingUnit: null,
      optionOperatingUnit: [],
      selectedTerritory: null,
      optionTerritory: [],
      selectedSales: null,
      optionSales: [],
      date: null,
      allChecked: false,

      table: this.tableDefaultState(),

      all_billing_order_ids: [],
      check_billing_order_ids: [],
      total_value: 0,
      selectedRow: true,
      selectedInvoice: null,
      selectedFaktur: null,
      searchBar: "",
      checkAllInvoice: false,
      selecteds: [],
      notes: "",
      searching_bar: "",
    };
  },
  watch: {
    selecteds: function (items) {
      // sum remaining value
      let total = 0;
      items.forEach((item) => {
        total += item.remaining_value;
      });
      this.total_value = total;
    },
    checkAllInvoice: function (val) {
      if (this.table.data != null) {
        this.table.data.forEach((item) => {
          item.use_invoice = val;
        });
      }
    },
  },
  mounted() {
    this.getOptionOperatingUnit({
      length: 0,
      page: 1,
    });
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
    tablesData() {
      if (this.searchBar) {
        return this.table.data.filter((item) => {
          if (
            item.customer
              .toLowerCase()
              .includes(this.searchBar.toLowerCase()) ||
            item.invoice_no.toLowerCase().includes(this.searchBar.toLowerCase())
          ) {
            return item;
          }
        });
      } else {
        return this.table.data;
      }
    },
    checkAll: {
      get() {
        return this.selectedRow;
      },
      set(value) {
        this.selectedRow = value;
        if (value) {
          this.table.data.forEach((item) => {
            item.check = true;
          });
        } else {
          this.table.data.forEach((item) => {
            item.check = false;
          });
        }
      },
    },
    checkAllFaktur: {
      get() {
        return this.selectedFaktur;
      },
      set(value) {
        this.selectedFaktur = value;
        if (value) {
          this.table.data.forEach((item) => {
            item.use_faktur = true;
          });
        } else {
          this.table.data.forEach((item) => {
            item.use_faktur = false;
          });
        }
      },
    },
  },
  methods: {
    onSearchTable(query) {
      console.log("query", query);
      this.searching_bar = query;
      this.onSubmitFilter();
    },
    formatNumber(val) {
      if (val) return formatNumber(val);
    },
    handleSelected(row) {
      let id = row.id;
      // check if exist on check_billing_order_ids do remove, else push id
      if (this.check_billing_order_ids.includes(id)) {
        this.check_billing_order_ids = this.check_billing_order_ids.filter(
          (item) => item !== id
        );
        console.log("remove id ", id);
      } else {
        this.check_billing_order_ids.push(id);
        console.log("push id ", id);
      }
    },
    customLabelSales(option) {
      return `${option.label}`;
    },
    async onSaveForm() {
      this.$vs.loading();
      const data = {
        date: this.date,
        type: 1,
        territory_id: this.selectedTerritory.territory_id,
        sales_personal_id: this.selectedSales.personal_id,
        all_billing_order_ids: this.all_billing_order_ids,
        notes: this.notes,
      };

      let routes = [];
      let check_billing_order_ids = [];
      this.selecteds.forEach((item) => {
        let route = {
          id: item.id,
          use_invoice: true,
          use_faktur: true,
        };
        routes.push(route);
        check_billing_order_ids.push(item.id);
      });
      check_billing_order_ids = uniqueArray(check_billing_order_ids);

      data.check_billing_order_ids = check_billing_order_ids;
      data.routes = routes;

      if (this.table.data.length == this.selecteds.length) {
        data.check_billing_order_ids = data.all_billing_order_ids;
        data.routes = [];
        data.routes = data.all_billing_order_ids.map((val) => {
          return {
            id: val,
            use_invoice: true,
            use_faktur: true,
          };
        });
      }

      const response = await this.$http.post(
        "/api/sfa/v1/collection-plan/save-route",
        data
      );

      if (response.code === 200) {
        this.$vs.notify({
          title: "Success",
          text: response.message,
          color: "success",
          iconPack: "feather",
          icon: "icon-check-circle",
        });
        this.$vs.loading.close();
        this.$router.push("/collection-plan-route");
      } else {
        this.$vs.notify({
          title: "Error",
          text: response.message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        this.$vs.loading.close();
      }
    },
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    async onSubmitFilter() {
      if (this.date == null) {
        this.$vs.notify({
          title: "Warning",
          text: "Set Collection Date is required",
          color: "warning",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }

      if (this.selectedTerritory == null) {
        this.$vs.notify({
          title: "Warning",
          text: "Set Territory is required",
          color: "warning",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }

      if (this.selectedSales == null) {
        this.$vs.notify({
          title: "Warning",
          text: "Set Sales is required",
          color: "warning",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }

      this.table.page = 1;
      let params = {
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        territory_id: this.selectedTerritory.territory_id,
        dafin_type: "on-route",
        date: this.date,
        is_show_all_first: true,
        sales_id: this.selectedSales.personal_id,
      };

      if (this.selectedTerritory != null) {
        params.territory_id = this.selectedTerritory.territory_id;
      }

      if (this.invoice_code != null) {
        params.invoice_code = this.invoice_code;
      }

      if (this.searching_bar != "") {
        params.search = this.searching_bar;
      }

      this.getData(params);
    },
    setDefaultCheckedRow() {
      this.allChecked = true;
      this.all_billing_order_ids = [];
      // // set checked all
      this.table.data.forEach((item) => {
        this.all_billing_order_ids.push(item.id);
      });
    },
    async onSearchChangeOperatingUnit(query) {
      if (query.length >= 3) {
        let response = await this.getOptionOperatingUnit({
          params: {
            search: query,
            page: 1,
          },
        });
        this.optionOperatingUnit = response.data.records;
      }
    },
    async getOptionSales(params) {
      this.$vs.loading();
      const response = await this.$http.get(
        "/api/sfa/v1/visit-plan-generate/sales",
        params
      );

      this.optionSales = response.data.records ? response.data.records : [];
      this.$vs.loading.close();
    },
    async onSelectTerritory(data) {
      const territory_id = data.territory_id;

      await this.getOptionSales({
        params: {
          territory_id: territory_id,
        },
      });
    },
    async getOptionTerritory(params) {
      this.$vs.loading();
      const response = await this.$http.get(
        "/api/sfa/v1/territory/operating-unit-territories",
        params
      );
      this.optionTerritory = response.data.records;
      this.$vs.loading.close();
    },
    async onSelectOperatingUnit(data) {
      const operating_unit_id = data.id;
      await this.getOptionTerritory({
        params: {
          operating_unit_id: operating_unit_id,
        },
      });
    },
    async getOptionOperatingUnit(params) {
      this.$vs.loading();
      const response = await this.$http.get("/api/sfa/v1/operating-unit", {
        params: params,
      });
      this.optionOperatingUnit = response.data.records;
      this.$vs.loading.close();
    },

    // table new
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleChangePage(page) {
      this.table.page = page;
      if (this.date == null) {
        return;
      }
      let params = {
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        dafin_type: "on-route",
        date: this.date,
        is_show_all_first: false,
        sales_id: this.selectedSales.personal_id,
      };

      if (this.selectedTerritory != null) {
        params.territory_id = this.selectedTerritory.territory_id;
      }

      if (this.searching_bar != "") {
        params.search = this.searching_bar;
      }

      this.getData(params);
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData({
        length: this.table.length,
        page: this.table.page,
        search: this.searching_bar != "" ? this.searching_bar : this.table.search,
        territory_id: this.selectedTerritory.territory_id,
        dafin_type: "on-route",
        date: this.date,
        is_show_all_first: false,
        sales_id: this.selectedSales.personal_id,
      });
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData({
        length: this.table.length,
        page: this.table.page,
        search: this.searching_bar != "" ? this.searching_bar : this.table.search,
        territory_id: this.selectedTerritory.territory_id,
        dafin_type: "on-route",
        date: this.date,
        is_show_all_first: false,
        sales_id: this.selectedSales.personal_id,
      });
    },
    getData(params) {
      this.check_billing_order_ids = [];
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: params,
        })
        .then((resp) => {
          if (resp.status == "OK") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.row_number_start = resp.data.row_number_start;
            if (resp.data.records.routes == null) {
              this.table.data = [];
            } else {
              this.table.data = resp.data.records.routes;
              this.selecteds = this.table.data;
              this.total_value = resp.data.records.total_value;
              if (resp.data.records.is_show_all_first) {
                this.all_billing_order_ids =
                  resp.data.records.all_billing_order_ids;
                this.check_billing_order_ids =
                  resp.data.records.all_billing_order_ids;
              }
            }
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
            this.$vs.loading.close();
          }
        });
    },
  },
  filters: {
    formatNumber(value) {
      if (value) {
        return formatNumber(value);
      }
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
  },
};
</script>
